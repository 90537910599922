<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload :api="this.api" @close="() => {
            this.api.isSuccesful = false;
        }" />
        <v-data-table v-if="!api.isLoading && data != null" :items="data" :headers="dataHeader" :search="search"
            class="elevation-1 mt-4">
            <template v-slot:item.Index="{ item }">
                {{ data.indexOf(item) + 1 }}
            </template>
            <template v-slot:top>
                <v-toolbar width="auto" height="auto" class="py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                   Companies from excel
                                </v-toolbar-title>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="primary" @click="fetch()">
                                    Refresh
                                </v-btn>
                                <JsonCSV :data="data" class="mx-2">
                                    <v-btn color="primary">
                                        Download
                                    </v-btn>
                                </JsonCSV>
                                <v-btn color="primary" @click="fix" class="mx-2">
                                    Fix
                                </v-btn>
                                <!-- <v-btn color="primary">
                                    New Record
                                </v-btn> -->
                            </v-row>
                            <v-row>
                                <v-text-field v-model="search" dense outlined label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
        </v-data-table>


    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';
export default {
    components: {
        ASuccessWithoutReload
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        dataSubscriptionHistory: null,
        searchSubscriptionHistory: '',
    
        data: null,
        search: "",
        dataHeader: [
            {
                text: '#',
                value: 'Index',
            },
            {
                text: 'Name',
                value: 'name',
            },
            {
                text: 'Excel key',
                value: 'excel_key',
            },
           
        ],
        isPending: false,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getCompaniesHistoySubscription") {
                this.data = resp.data;
            }
            // if (resp.class === "getSubscriptionHistory") {
            //     this.dataSubscriptionHistory = resp.data;
            // }
            if (resp.class === "fixSubscriptionHistory") {
                this.api.isSuccesful = true;
                this.api.success = resp.data;
            }
            if (resp.class === "deleteSubscriptionHistory") {
                this.fetch();
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchSubscriptionHistoryExcelApi = this.fetchSubscriptionHistoryExcel();
            let fetchSubscriptionHistoryApi = this.fetchSubscriptionHistory();
            this.$axios.all([
                this.$api.fetch(fetchSubscriptionHistoryExcelApi),
                this.$api.fetch(fetchSubscriptionHistoryApi),

            ]);
        },
        fetchSubscriptionHistoryExcel() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/excel/subscription_history";
            return tempApi;
        },
        fetchSubscriptionHistory() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/subscription_history/"
            return tempApi;
        },
        manuallyFetchSubscriptionHistory() {
            let fetchSubscriptionHistoryApi = this.fetchSubscriptionHistory();
            this.$api.fetch(fetchSubscriptionHistoryApi);
        },

        validateInput() {
            this.isPending = true;
        },
        cancelSubmit() {
            this.isPending = false;
        },
        submit() {
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LL");
        },
        fix() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "post";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/excel/subscription_history/fix/" + this.$store.getters.getUserId;
            this.$api.fetch(tempApi);
        },
        Delete() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/subscription_history/" + this.$store.getters.getUserId;
            this.$api.fetch(tempApi);

        }
    }
}
</script>