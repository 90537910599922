<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload :api="this.api" @close="closeSuccesfulModal()"/>
        <v-row>
            <v-col align="center" justify="center">
                <div>
                    <v-btn color="primary" :loading="api.isLoading" @click="updateVisionKey()">
                        Update Vision Key
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';
export default {
    components:{
    ASuccessWithoutReload
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        dataHeader:[
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="updateVisionKey") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update the vision key";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchFailedCouponApi = this.fetchFailedCoupon();

            this.$api.fetch(fetchFailedCouponApi);
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        updateVisionKey(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            let url = process.env.VUE_APP_SERVER_API.replace('v1','v2');
            tempApi.url = url+"/developer/vision_key";
            this.$api.fetch(tempApi);
        },
        closeSuccesfulModal(){
            this.api.isSuccesful = false;
        }
    }
}
</script>